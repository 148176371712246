.indicator {
    li {
        width: 8px;
        height: 8px;
        background-color: #D9D9D9;
        border-radius: 50%;
        &.active {
            width: 22px;
            border-radius: 14px;
            background-color: #FF6737;
        }
    }
}